(function(window, $){
	'use strict';
	var $lawyerSearch = $('#lawyer-search');
	var $lawyerSearchButton = $('#lawyer-search-button');

	if ($lawyerSearch.length > 0 && $lawyerSearchButton.length > 0) {
	    '99999999-9999-9999-9999-999999999999'
	    var searchUrl = $lawyerSearch.attr("data-search-url");
	    console.log(searchUrl);
	    if (!!searchUrl && searchUrl.length > 0) {
	        $lawyerSearchButton.on("click tap", function () {
	            var selectedVal = $lawyerSearch.val();
	            if (!!selectedVal && selectedVal.length > 0) {
	                window.location = searchUrl.replace('99999999-9999-9999-9999-999999999999', selectedVal);
	            }
	        });
	    }
	}

})(window, jQuery);