(function (factory) {
    'use strict';

    // AMD
    if (typeof define === 'function' && define.amd)
    {
        define(['jquery'], factory);
    }
    else
        // browser globals
    {
        factory(jQuery);
    }

}(function ($) {


    $('a[data-mailto-domain]').each(function(i, el) {
        var mailto = "mailto:" + $(el).data('mailto-name').replace(/##/g, "'") + "@" + $(el).data('mailto-domain').replace(/##/g, "'");
        $(el).attr('href', mailto);
    });

    $("span.spamproof_insert").replaceWith("@");
    
}));

