//IE setTimeout Fix http://stackoverflow.com/questions/12404528/ie-parameters-get-undefined-when-using-them-in-settimeout
if (document.all && !window.setTimeout.isPolyfill) {
	var __nativeST__ = window.setTimeout;
	window.setTimeout = function (vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */) {
		var aArgs = Array.prototype.slice.call(arguments, 2);
		return __nativeST__(vCallback instanceof Function ? function () {
			vCallback.apply(null, aArgs);
		} : vCallback, nDelay);
	};
	window.setTimeout.isPolyfill = true;
}

if (document.all && !window.setInterval.isPolyfill) {
	var __nativeSI__ = window.setInterval;
	window.setInterval = function (vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */) {
		var aArgs = Array.prototype.slice.call(arguments, 2);
		return __nativeSI__(vCallback instanceof Function ? function () {
			vCallback.apply(null, aArgs);
		} : vCallback, nDelay);
	};
	window.setInterval.isPolyfill = true;
}


(function(window, $){
	'use strict';
	

	var $home = $('.home');
	var animateOutClass = 'animateOut',
		animateInClass = 'animateIn';

	function isIeOlderThan(version) {
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf("MSIE ");

		if (msie > 0){
			var currVersion = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
			return currVersion < version;
		}
		return false;
	}
	
	//TODO: determine if you are on homepage or not and figure homepage. Can be removed
	if ($home.length > 0) {

		fancySlider();
	}

	function fancySlider(startSlide) {

		var $scrollWindow = $(window),
			scrollPosition = 1500,
			timer,
			$fancySlideWrapper = $('.fancy-slides'),
			$fancySlides = $fancySlideWrapper.find('.fancy-item'),
			$fancySlidesNav = $fancySlideWrapper.find('.fancy-slides-nav-btn'),
			$fancySlideNextTrigger = $fancySlideWrapper.find('.js-fancy-slide-trigger'),
			slideLength = $fancySlides.length - 1,
			slideIndex = startSlide || 0,
			slidePreviousIndex,
			animateTimer,
			animateClass,
			classResetTimer,
			animationRunning = false,
			//in ms
			animationDurationOut = 1250,
			animationDurationIn = 750;


		//EventHandlers - Arrow Trigger

		if (slideLength  < 1 ) {
			$fancySlideNextTrigger.addClass('is-inactive');
		}

		else {

			$fancySlideNextTrigger.on('click.fancyTrigger', function() {

				//Dont do anything if the animation is running
				if (animationRunning) {
					return false;
				}


				slidePreviousIndex = slideIndex,
				++slideIndex; 

				if (slideIndex > slideLength ) {
					slideIndex = 0;
				}

				runAnimation(slidePreviousIndex, slideIndex);
				animationRunning = true;

			});
		}



		$fancySlidesNav.on('click.nav', function(){

			var $self = $(this);

			//dont do anythign if animation is runnign otherwise bad things will happen
			if (animationRunning) {
				return false;
			}

			var clickedIndex = $fancySlidesNav.index($self);

			slidePreviousIndex = slideIndex;

			//determine if you clicked the same item - if you do dont do anything
			if (slidePreviousIndex === clickedIndex  ) {
				return	 false;
			}

			runAnimation(slidePreviousIndex, clickedIndex);

			slideIndex = clickedIndex;

			animationRunning = true;
		});



		function runAnimation(currentSlide, nextSlide) {
			//FadeoutSuckers
			$fancySlides.eq(currentSlide).addClass(animateOutClass);

			animateTimer = setTimeout(fadeInSlide, animationDurationOut, currentSlide, nextSlide);

		}


		function fadeInSlide(currentSlide, nextSlide) {
			
			clearTimeout(animateTimer);

			if(isIeOlderThan(10)){
				$fancySlides.eq(currentSlide).fadeOut();
				$fancySlides.eq(nextSlide).fadeIn();
				$fancySlides.eq(currentSlide).removeClass('js-is-active ' + animateOutClass);
				$fancySlides.eq(nextSlide).addClass('js-is-active ' + animateInClass);
			} else{
				$fancySlides.eq(currentSlide).removeClass('js-is-active ' + animateOutClass);
				$fancySlides.eq(nextSlide).addClass('js-is-active ' + animateInClass);
			}

			updateNav(nextSlide);

		
			classResetTimer = setTimeout(resetClasses, animationDurationIn, currentSlide, nextSlide);
		}

		function resetClasses(currentSlide, nextSlide) {
			$scrollWindow.scrollTop(scrollPosition);

			$fancySlides.eq(nextSlide).removeClass(animateInClass);

			animationRunning = false;

			setTimeout(function() {

				$scrollWindow.on('scroll.fancyScroll', throttle)},

			300);
		}

		function updateNav(nextSlide){
			$fancySlidesNav.removeClass('is-active');
			$fancySlidesNav.eq(nextSlide).addClass('is-active');
		}


		//Attach scroll event after the window offset has been set. 
		setTimeout(function(){
			$scrollWindow.on('scroll.fancyScroll', throttle);
			checkMobile();
		}, 800);
		
		//Adjust the position of the scrollbar to an offset of the page. 

		if (window.innerWidth >= 750) {
			$scrollWindow.scrollTop(scrollPosition);
		}

		//On Scroll
		function throttle() {

			//console.log('throttle');

			if(timer)
				clearTimeout(timer);
			
			timer = setTimeout(scrollEvent, 300);
			
		}
				
		function scrollEvent() {

			if ($scrollWindow.scrollTop() > scrollPosition)
				nextSlide();
			else
				prevSlide();
		}

		function nextSlide() {
			$scrollWindow.off('scroll.fancyScroll');

			$fancySlideNextTrigger.trigger('click.fancyTrigger');
		}

		function prevSlide() {

			
			//Dont do anything if the animation is running
			if (animationRunning) {
				return false;
			}

			slidePreviousIndex = slideIndex,

			--slideIndex; 

			

			if (slideIndex < 0 ) {
				
				slideIndex = 0;
				
				return false;
			}

			$scrollWindow.off('scroll.fancyScroll');

			runAnimation(slidePreviousIndex, slideIndex);
			animationRunning = true;

		}

		//If in Mobile view remove desktop event Handlers

		function checkMobile() {


			if (window.innerWidth < 750) {

				$fancySlideNextTrigger.off('click.fancyTrigger');

				$scrollWindow.off('scroll.fancyScroll');

				mobileTrigger();
			}

			else {
				$fancySlideNextTrigger.off('click.mobileFancyTrigger');
			}

		}

		function mobileTrigger(){

			$fancySlideNextTrigger.on('click.mobileFancyTrigger', function(){
				scrollNextSlide();

				$fancySlideNextTrigger.off('click.mobileFancyTrigger');
			});
		}

		function scrollNextSlide() {
			var $siteBody = $('body'),
			 	mobileHeaderOffset = 70,
				scrollYPosition = $scrollWindow.scrollTop() + mobileHeaderOffset,
				fancySlideCurrent = -1;

			

			//we are at the top of the page so we dont need to compute position since we can proceed to the next slide
			if ( scrollYPosition === mobileHeaderOffset ) {
				$siteBody.animate({
					scrollTop: $fancySlides.eq(1).offset().top - mobileHeaderOffset
				}, 100)
			}

			else {
				$fancySlides.each(function(index) {


					if ( 
						scrollYPosition >= parseInt($fancySlides.eq(index).offset().top) && 
						scrollYPosition < $fancySlides.eq(index).offset().top + $fancySlides.eq(index).outerHeight()
					) {

						fancySlideCurrent = index;
					}

				});

				

				if (fancySlideCurrent.length !== -1 )  {

					

					++fancySlideCurrent;

					if (fancySlideCurrent > slideLength ) {
						fancySlideCurrent = 0;
					}

					

					$siteBody.scrollTop($fancySlides.eq(fancySlideCurrent).offset().top - mobileHeaderOffset);
					
					mobileTrigger();

					//animating scroll
					// $siteBody.animate({
					// 	scrollTop: $fancySlides.eq(fancySlideCurrent).offset().top - mobileHeaderOffset
					// }, 100);

					fancySlideCurrent = null;
				}

				else {
					$siteBody.scrollTop(0);

					mobileTrigger();
				}

			}
		}


		var resizeTimer;

	    $(window).on('resize', function(){
	        clearTimeout(resizeTimer);
	        resizeTimer = setTimeout(checkMobile, 300);
	    });
		
	}

})(window, jQuery);