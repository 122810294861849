(function (window, $) {
    var $modal = $('.modal');
    var $submitModal = $('#submit-modal');
    var $downloadResource = $('.download-resource-link');

    $.fn.extend({
        required: function() {
            return this.hasClass('required-field');
        }
    });

    $(document).ready(function () {
        $downloadResource.on('click', function (event) {
            event.stopPropagation();
            event.preventDefault();
            $('html body').animate({ scrollTop: 0 }, 'slow', function () {
                $modal.show();
            });
        });

        $(document).on('click', function (event) {
            if (!$(event.target).closest('.modal').length) {
                if ($modal.css('display') == 'block') {
                    $modal.hide();
                }
            }
        });

        $submitModal.on('click', function() {
          if (typeof recaptchaSiteKey !== 'undefined') {
            grecaptcha.ready(function () {
              grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function (token) {
                if (validate()) {
                  document.getElementById("submit-modal").disabled = true;

                  var request = {
                    'firstName': getValue('firstName'),
                    'lastName': getValue('lastName'),
                    'emailAddress': getValue('emailAddress'),
                    'cityState': getValue('citystate'),
                    'pdfName': getValue('pdfName'),
                    'pdfUrl': getValue('pdfUrl'),
                    'sendToEmail': getValue('sendToEmail'),
                    'recaptchaToken': token
                  }

                  $.ajax({
                    type: 'POST',
                    url: '/api/FormApi/ResourceLandingModalSubmit',
                    data: request,
                    dataType: 'json',
                    success: function (data) {
                      if (data != "500") {
                        window.location.href = data.redirect;
                        document.getElementById("submit-modal").disabled = false;
                      }
                      else {
                        $('.error-message').show();
                        document.getElementById("submit-modal").disabled = false;
                      }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                      $('.error-message').show();
                      window.location.href = request.pdfUrl;
                      document.getElementById("submit-modal").disabled = false;
                    }
                  });
                }
              });
            });
          }
          else {
            $('.error-message').show();
          }
        });
    });

    function validate() {
        var passed = true;
        $('input').each(function() {
            var $this = $(this);
            if ($this.required() && !$this.val()) {
                error($this, $this.attr('data-field') + ' is required');
                passed = false;
            } else if ($this.is('#email') && !validateEmail($this.val())) {
                error($this, 'Invalid email');
                passed = false;
            } else if ($this.is('#phone') && $this.val() && !validatePhone($this.val())) {
                error($this, 'Invalid phone number');
                passed = false;
            }
        });
        return passed;
    }

    function error($input, errorMessage) {
        $input.val('');
        $input.attr('placeholder', errorMessage);
        $input.addClass('validation-failed');
    }

    function validatePhone(input) {
        var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return regex.test(input);   
    }
    
    function validateEmail(input) {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(input);
    }

    function getValue(name) {
        var $input = $('input[name=' + name + ']');
        return $input.val();
    }

})(window, jQuery);