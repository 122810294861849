(function (window, $) {
    'use strict';

    var $tabSlides = $('.js-tab-slides');

    if ($tabSlides.length > 0) {
        tabSlides();
    }



    function tabSlides() {
        var
			// Navigation links for every section on the page
			tabNavLinks = $tabSlides.find('.tab-nav-link:not(.tab-nav-link-sec)'),

			//All Content Slides
			tabSlides = $tabSlides.find('.tab-slides-item'),

			//tab Length
			tabSlidesLength = tabSlides.length - 1,


			tabActiveClassName = 'js-tab-slide-active',

			//Height of Current Slide
			tabHeight,

			//Height of Next  Slide
			tabClickedHeight,

			//Keeps track of which slide you are on
			tabIndex = 0,

			//Which way to slide tab
			tabDirection,

			//temporary variable that is used to keep track of previous index
			tabPreviousIndex = 0,

			contentBody = $tabSlides.find('.js-tab-slides-wrapper'),

			siteBody = $('html,body'),

			$window = $(window),

			windowWidth,

			windowOffset,

			isAnimating,

			contentBodyOffset,

			siteBodyOffset;



        function calculateOffset() {
            windowWidth = window.innerWidth;

            contentBodyOffset = contentBody.offset().top;

            (windowWidth < 600) ? siteBodyOffset = contentBodyOffset : siteBodyOffset = 0;
        }


        function directionAnimationLogic(currentTabIndex, clickedTabIndex) {

            //if the tabindex is smaller than the next tab, then we will animate right to left, otherwise we'll go left to right;
            return (currentTabIndex < clickedTabIndex) ? 'Right' : 'Left';

        }

        var tabNav, tabNavDropdownTrigger;
        function tabDropdown() {
            // Navigation links for every section on the page
            if (!tabNav) {
                tabNav = $tabSlides.find('.js-tab-slides-nav'),
					tabNavDropdownTrigger = $tabSlides.find('.js-tab-nav-label');


                tabNavDropdownTrigger.on('click.tabDropdown', function (ev) {
                    tabNavDropdownTrigger.toggleClass('is-active-nav');

                });

                tabNav.on('click.tabsMobile', function (ev) {
                    tabNavDropdownTrigger.trigger('click.tabDropdown');
                });
            }
        }

        function tabMobileJump() {
            var tabTriggers = $tabSlides.find('.tab-nav-link'),
	        	siteBody = $('html,body'),
	        	windowWidth = window.innerWidth;


            tabTriggers.on('click.mobileJump', function (ev) {
                ev.preventDefault();

                var _self = $(this),
	                targetHref = _self.attr('href'),
	                targetLocation = $("a[name=" + targetHref.slice(1) + "]");

                siteBody.animate({
                    scrollTop: targetLocation.offset().top
                }, 300);
            });
        }


        function resize() {
            calculateOffset();
            if (windowWidth > 600) {
                tabNavLinks.on('click.tabsContent', function (ev) {

                    var self = $(this);

                    ev.preventDefault();

                    //Stop from performing if the animation is running, otherwise shit will break
                    if (isAnimating === true) return false;


                    //updating clicked index
                    var clickedIndex = tabNavLinks.index(self);

                    tabPreviousIndex = tabIndex;

                    //Update tab index
                    tabIndex = clickedIndex;

                    //determine if you clicked the same tab - if you do dont do anything
                    if (tabPreviousIndex === clickedIndex) {
                        return false;
                    }

                    //update Navigation Link
                    tabNavLinks.removeClass('is-active');
                    self.addClass('is-active');

                    //update state of animation
                    isAnimating = true;

                    tabDirection = directionAnimationLogic(tabPreviousIndex, clickedIndex);



                    animateSlide(tabDirection, tabPreviousIndex, clickedIndex);

                });
            }
            else {
                tabNavLinks.off('click.tabsContent');
                tabDropdown();
                tabMobileJump();
            }
        }
        //resize();

        $(window).resize(resize);
        function animateSlide(slideDirection, currentTabIndex, clickedTabIndex) {
            tabHeight = contentBody.height();


            tabClickedHeight = tabSlides.eq(clickedTabIndex).height() + 38;

            //set the wrapper the height of the current container
            contentBody.css('height', tabHeight + 'px');


            if (windowWidth < 650) {

                siteBody.animate({
                    scrollTop: siteBodyOffset
                }, 700);

                tabClickedHeight = tabSlides.eq(clickedTabIndex).height() + 20;
            }

            else if ($window.pageYOffset > contentBodyOffset) {

                contentBodyOffset = contentBodyOffset - 129;

                siteBody.animate({
                    scrollTop: contentBodyOffset
                }, 700);
            }

            if (slideDirection === 'Right') {



                //Slide the current tab off to the left
                tabSlides
					.eq(currentTabIndex)
					.animate({
					    top: currentTabIndex > clickedTabIndex ? "100%" : "-100%"
					}, {
					    duration: 200,

					    complete: function () {
					        $(this)
								.css('display', 'none')
								.removeClass(tabActiveClassName);
					    }
					}
				);


                //Slide the CLicked tab off to the left

                tabSlides
					.eq(clickedTabIndex)
					.css('display', 'block')
					.animate({
					    top: "0"
					}, {
					    duration: 600,
					    complete: function () {
					        $(this)
								.css('top', '')
								.addClass(tabActiveClassName);

					        //reset the wrapper to the slide animated in
					        contentBody.animate({ 'height': tabClickedHeight }, 400, function () {
					            $(this).css('height', '');

					            isAnimating = false;
					        });
					    }


					}
				);
            }

            else {
                //Slide the current tab off to the right
                tabSlides
					.eq(currentTabIndex)
					.animate({
					    top: currentTabIndex > clickedTabIndex ? "100%" : "-100%"
					}, {
					    duration: 200,

					    complete: function () {
					        $(this)
								.css('display', 'none')
								.removeClass(tabActiveClassName);
					    }
					}
				);

                //Slide the CLicked tab off to the right

                tabSlides
					.eq(clickedTabIndex)
					.css(
							{
							    'top': -tabClickedHeight,
							    'display': 'block'
							}
						)
					.animate({
					    top: "0"
					}, {
					    duration: 600,
					    complete: function () {
					        $(this)
								.css('top', '')
								.addClass(tabActiveClassName);

					        //reset the wrapper to the slide animated in
					        contentBody.animate({ 'height': tabClickedHeight }, 400, function () {
					            $(this).css('height', '');

					            isAnimating = false;
					        });

					    }
					}
				);
            }
        }
    }

})(window, jQuery);