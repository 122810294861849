
$(document).ready(function(){
    //intercept print function to remove width
    //Ipad needed special fix
    var isiPad = navigator.userAgent.match(/iPad/i);
    if ( isiPad) {
      $('body').addClass('ipad');
    }

    $(document).bind("keyup keydown", function(e){
        if(e.ctrlKey && e.keyCode == 80){
            e.preventDefault();
            $('.site-main-content').attr('style','width:1300px');
            window.print();
            return false;
        }
    });
    //scan for email addresses
    $.each($('a[href^="javascript:SPMail.SendEmail("]'),function(ndx,item){
        var $item = $(item);
        var emailAddress = $item.attr('href').replace('javascript:SPMail.SendEmail(','').replace("','","@").replace(');',"").replace(/'/g,"");
        $item.attr('href', "javascript:openEmailDisclaimer('" + emailAddress + "')");
    });


    var $trigger = $('.js-mobile-toggle'),
        $menu    = $('.site-nav');

    function toggleNav() {
        $menu.toggleClass('active-nav');
    }

    if($trigger.length > 0)
        $trigger.click(toggleNav);

    //placeholder
    $('input,textarea').focus(function () {
        $(this).data('placeholder', $(this).attr('placeholder'))
        $(this).attr('placeholder', '');
    });
    $('input,textarea').blur(function () {
        $(this).attr('placeholder', $(this).data('placeholder'));
    });

    //open file links in new window
    $('a').each(function (index) {
        var href = $(this).attr('href');
        if (!href) { return; }
        href = href.trim().toLowerCase();
        if (href.match(/.pdf$/) || href.match(/.doc$/) || href.match(/.docx$/)) {
            $(this).attr('target', '_blank');
        }
    });
});
function isIE() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer, return version number
        return true;
    return false;
}
if (isIE()) {
    $.ajaxSetup({ cache: false });
}
$(document).ready(function(){
    var $results = $('.js-results-section'),
        $letters = $('.alpha-list-link'),
        $searchBox = $('.js-search-box, .select-dropdown select, .client-achievement-select, .news-select');

	if(window.location.search.indexOf('=') > -1){
		var value = window.location.search.split("=")[1];
		$('.keyword-search').val(value);
		showResults();
	}
    //will fire when enter it hit and focus is lost, or when select isused
    $searchBox.change(showResults);
    $letters.click(showResults);

    function showResults() {
        $results.show();
	    
	    if($results.parent().hasClass('news-top')){
		    $results.parent().nextAll().hide();
		    $results.nextAll().hide();
		    $('.news-sidebar').show();
	    }
	    else{
		    $results.nextAll().hide();    
	    }
	    
	    
    }

});

$(document).ready(function(){
    var $flipCards = $('[data-flip-card] .container');

    function toggleFlip() {
        var $this = $(this);

        $this.toggleClass('flipped');
    }

    function sizeCard() {
        var $this = $(this);
            $img = $('.card-front img');
    }

    if($flipCards.length > 0) {
        $.each($flipCards,function(ndx,flipcard){
            var $flipCard = $(flipcard);
            if(!$flipCard.hasClass('disabled')){
                $flipCard.click(toggleFlip);
            }
        })
    }
});

$(document).ready(function(){
    var $triggers = $('[data-accordion-trigger]');

    function toggleAccordion() {
        var $this = $(this);
        $this.parent().toggleClass('active-accordion');
        $this.parent().children('.accordion-content').slideToggle();
        if ($this.children('.trigger').length > 0) {
            var $trigger = $this.children('.trigger');
            var text = $trigger.text() == '+' ? '-' : '+';
            $trigger.text(text);
        }
    }

    if($triggers.length > 0) {
        $('.accordion-content').slideUp();
    }

    $('.accordion-component').on('click', '[data-accordion-trigger]', toggleAccordion);

    var $industryTriggers = $('[data-industry-trigger]');

    function toggleIndustry(e) {
        e.preventDefault();
        var $this = $(this);
        var text = $this.text() == '+' ? '-' : '+';
        $this.text(text);
        $this.parent().parent().parent().toggleClass('active-accordion');
        $this.parent().parent().next().slideToggle();
    }

    if($industryTriggers.length > 0) {
        $('.accordion-content').slideUp();
        $industryTriggers.click(toggleIndustry);
    }

    var $expandAll = $('[data-accordion-expand-all]');
    if ($expandAll.length) {
        $expandAll.click(expandAllAccordians);
    }

    function expandAllAccordians() {
        if($expandAll.text().trim() == "Expand All"){
            $expandAll.text("Collapse All");
            $triggers.each(function (index, item) {
                var $this = $(item).find('.trigger');
                if ($this.text().trim() == '+'){
                    $this.text('-');
                    $this.parent().parent().addClass('active-accordion');
                    $this.parent().next().slideToggle();
                }
            });

        }else{
            $expandAll.text("Expand All")
            $triggers.each(function (index, item) {
                var $this = $(item).find('.trigger');
                if ($this.text().trim() == '-'){
                    $this.text('+');
                    $this.parent().parent().removeClass('active-accordion');
                    $this.parent().next().slideToggle();
                }
            });
        }
    }
});

function sendEmail(email) {
    setTimeout(function () { window.close(); }, 300);
    window.location.href = 'mailto:' + email;
}

function openEmailDisclaimer(email) {
    window.open('/EmailDisclaimer?email=' + email, 'disclaimer', 'height=540,width=640,location=no,menubar=no,resizable=no,scrollbars=no,toolbar=no,status=no,left=100,top=100');
}

onReady(function(){
    var $trigger = $('.site-search-toggle'),
        $search = $('.site-search'),
        $button = $('.site-search .search-button'),
        $input = $('.site-search .search-input');

    function toggleSearch() {
        $search.slideToggle();
    }

    function goToResults() {
        var query = $input.val();
        window.location.href = '/search?q=' + encodeURIComponent(query);
    }

    function closeSearch(e) {
        if (e.keyCode == 27) {
            e.preventDefault();
            $search.slideUp();
        }
    }

    $trigger.click(toggleSearch);

    $button.click(goToResults);

    $input.on("keypress", function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            goToResults();
        }
        
    });

    $input.on("keyup", closeSearch);

    $trigger.on("keyup", closeSearch);

    $button.on("keyup", closeSearch);

    var $socialLinks = $('.social-links-inpage');

    if ($socialLinks.length > 0 ) {
        var $socialLinksTrigger = $socialLinks.find('.social-links-trigger');

        $socialLinksTrigger.on('click.socialLinks', function(ev){
            ev.preventDefault();

            $socialLinks.toggleClass('is-expanded');
        });
    }
});

$(window).resize(function() {
    /* The jquery calc code */
    $('.site-main-content').css('width', '100%').css('width', '-=100px');
});

function onReady(fn) {
    $(document).ready(fn);
}

$(document).ready(function () {
  var $triggers = $('[data-autocomplete-trigger]');

  if ($triggers.length > 0) {
    $triggers.each(function (index) {
      var acInput = $(this);
      var autoCompleteUrl = acInput.attr("data-autocomplete-url");
      var ac = acInput.autocomplete({
        serviceUrl: autoCompleteUrl,
        noCache: true,
        minChars: 2,
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        params: {},
        onSelect: function (value) {
          if (!!value.Url && value.Url != '') {
            window.location.href = value.Url;
          }
        },
        transformResult: function (response, originalQuery) {
          return {
            suggestions: $.map(response, function (dataItem) {
              return { data: dataItem.ID, value: dataItem.Name, Url: dataItem.Url };
            })
          };
        }
      });
    });
  }

  $("#ccpaForm").submit(function (e) {
    e.preventDefault();

    if (typeof recaptchaSiteKey !== 'undefined') {
      grecaptcha.ready(function () {
        grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function (token) {
          var $form = $("form[id='ccpaForm']");
          var valid = true;

          $form
            .find("input, select")
            .filter("[required]")
            .filter(function () { return this.value == '' || this.value == $(this).attr('placeholder'); })
            .each(function () {
              e.preventDefault();
              $(this).next().show();
              valid = false;
            });

          if (valid) {
            var data = { recaptchaToken: token };
            document.getElementById("submitBtn").disabled = true;

            $form.serializeArray().map(function (x) { data[x.name] = x.value; });

            $.ajax('/api/FormApi/CCPAForm', {
              type: 'POST',
              data: data,
              success: function (resp) {
                if (resp != "500") {
                  $('.content .overview').remove();
                  $('.content form').remove();
                  $('.success-message').show();
                }
                else {
                  $('.error-message').show();
                  document.getElementById("submitBtn").disabled = false;
                }
              }
            })
          }
        });
      });
    }
    else {
      $('.error-message').show();
    }
  });

  //contact form non html5 shim
  // fix for IE < 11
  //if ($("<input />").prop("required") === undefined) {
  $("form[id='contactForm']").on("submit", function (e) {
    e.preventDefault();

    if (typeof recaptchaSiteKey !== 'undefined') {
      grecaptcha.ready(function () {
        grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function (token) {
          var $form = $("form[id='contactForm']");
          var valid = true;

          $form
            .find("input, select, textarea")
            .filter("[required]")
            .filter(function () { return this.value == '' || this.value == $(this).attr('placeholder'); })
            .each(function () {
              e.preventDefault();
              $(this).next().show();
              valid = false;
            });

          if (valid) {
            var data = { recaptchaToken: token };
            document.getElementById("submitBtn").disabled = true;

            $form.serializeArray().map(function (x) { data[x.name] = x.value; });

            $.ajax('/api/FormApi/ContactSubmit', {
              type: 'POST',
              data: data,
              success: function (resp) {
                if (resp != "500") {
                  $('.content .overview').remove();
                  $('.content form').remove();
                  $('.success-message').show();
                }
                else {
                  $('.error-message').show();
                  document.getElementById("submitBtn").disabled = false;
                }
              }
            })
          }
        });
      });
    }
    else {
      $('.error-message').show();
    }
  });

    $('input,select,textarea').on('blur',function(){
        if($(this).attr('type') == 'checkbox')
            return;
        if($(this).attr('required') && !(this.value == '' || this.value == $(this).attr('placeholder'))){
            $(this).next().hide();
        }
    });

    //Read More
    $('.read-more-link').click(function() {
        $this = $(this);
        if ($this.prev().hasClass('show')) {
            $this.prev().removeClass('show');
            $this.text('Read More +');
        } else {
            $this.prev().addClass('show');
            $this.text('Read Less -');
        }
    });
    
    //youtube responsive width code (rich text youtube embed)
    // Find all YouTube videos
    var $allVideos = $("iframe[src^='//www.youtube.com']");


    // Figure out and save aspect ratio for each video
    $allVideos.each(function () {

        $(this)
          .data('aspectRatio', this.height / this.width)

          // and remove the hard coded width/height
          .removeAttr('height')
          .removeAttr('width');

    });

    // When the window is resized
    $(window).resize(function () {


        // Resize all videos according to their own aspect ratio
        $allVideos.each(function () {
            var $el = $(this);
            var newWidth = $el.closest('.content-block').width();

            $el
              .width(newWidth)
              .height(newWidth * $el.data('aspectRatio'));

        });

        // Kick off one resize to fix all videos on page load
    }).resize();


});
